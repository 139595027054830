* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  font-family: Inter, sans-serif;
  font-size: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button,
a,
input,
textarea {
  font-family: Inter, sans-serif;
}

button,
a {
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.optionContainer {
  font-size: 16px;
}

.optionContainer .highlight {
  background: #55b653;
  color: #fff;
}

.optionContainer .option:hover {
  background: #55b653;
  color: #fff;
}

.check-container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  height: 20px;
  min-width: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.check-container > input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #434242;
}

.check-container:hover input ~ .checkmark {
  background-color: #575656;
}

.check-container input:checked ~ .checkmark {
  background-color: #55b653;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.check-container input:checked ~ .checkmark:after {
  display: block;
}

.check-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
